<script>
import Layout from "@/router/layouts/store";
import { api } from "@/state/services";
import Product from "@/components/widgets/loading-product";
import Buy from "@/components/widgets/store-buy";

export default {
  locales: {
    pt: {
      'Products': 'Produtos',
    },
    es: {
      'Products': 'Productos',
    }
  },
  components: { Layout, Product, Buy },
  data() {
    return {
      products: {
        loading: true,
        errored: false,
        empty: false,
        list: null,
      }
    };
  },
  methods: {
    getProducts() {
      api
        .get('store/featured')
        .then(response => {
          if (response.data.status=='success') {
            this.products.list = response.data.list
            this.products.loading = false
          }
        })
        .catch(error => {
          this.products.errored = error
          this.products.loading = false
        })
        .finally(() => {
          this.products.loading = false
        })
    }
  },
  mounted() {
    this.getProducts()
  }
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex justify-content-between">
          <h4 class="mb-0 font-size-18">{{ t('Products') }}</h4>
        </div>
      </div>
    </div>
    <div v-if="products.loading" class="row">
      <div class="col-md-6 col-lg-4">
        <div class="card card-h-fix">
          <div class="card-body text-center">
            <Product></Product>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-lg-4">
        <div class="card card-h-fix">
          <div class="card-body text-center">
            <Product></Product>
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="!products.list || products.list && products.list.length == 0" class="card">
      <div class="card-body">
        <div class="text-center py-5">
          <i class="far fa-sad-cry font-size-24"></i><br>
          ainda não temos nenhum produto disponível nesta categoria
        </div>
      </div>
    </div>
    <div v-else class="row">
      <div class="col-md-4" v-for="(product,id) in products.list" :key="id">
        <div class="card card-h-fix">
          <div class="card-body text-center">
            <div class="mb-3">
              <b-carousel
                style="margin:0 auto; max-width:350px;"
                controls
                indicators
                fade
                :interval="0">
                <b-carousel-slide style="max-width:350px; min-height:200px" v-for="(image, i) in product.images" :key="i" :img-src="image"></b-carousel-slide>
              </b-carousel>
            </div>
            <p>
              {{ product.name }}<br>
              {{ product.price }}
            </p>
            <Buy :id="product.id" :variations="product.variations"></Buy>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>